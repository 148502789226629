<template>
  <el-card shadow="never" style="min-height: 750px">
    <el-card header="基本信息" class="box-card" shadow="never">
      <el-row class="baseInfo">
        <el-col :span="8">
          <div class="item">
            <label>用户名:</label>
            <span class="value">{{ user.name }}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <label>手机号码:</label>
            <span class="value">{{ user.mobile }}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <label></label>
            <span class="value"></span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <label>证件类型:</label>
            <span class="value" v-if="user.papersType == 1">居民身份证</span>
            <span class="value" v-else-if="user.papersType == 2">台湾来往大陆通行证</span>
            <span class="value" v-else-if="user.papersType == 3">澳门来往大陆通行证</span>
            <span class="value" v-else-if="user.papersType == 4">香港来往大陆通行证</span>
            <span class="value" v-else-if="user.papersType == 5">护照</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <label>证件号码:</label>
            <span class="value">{{ user.idNumber }}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <label>身份验证:</label>
            <el-link type="warning" class="value" v-if="user.certificationStatus == 'UNVERIFIED'">未认证</el-link>
            <el-link type="success" class="value" v-else-if="user.certificationStatus == 'AUTHENTICATIONOK'">认证成功</el-link>
            <el-link type="danger" class="value" v-if="user.certificationStatus == 'AUTHENTICATION_FAILURE'">认证失败</el-link>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card header="工商信息" class="box-card" shadow="never">
      <el-row class="baseInfo">
        <el-col :span="12">
          <div class="item">
            <label>个体户名称:</label>
            <span class="value">{{user.firmName?user.firmName:'-'}}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="item">
            <label>营业执照号:</label>
            <span class="value">{{user.yyzzNo?user.yyzzNo:'-'}}</span>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card header="选择银行" class="box-card" shadow="never">
      <div class="box-bank">
        <span v-if="user._bankName!=null" class="bankname"> {{ user._bankName }} </span>
        <span v-if="user._bankName!=null" class="no">{{ user._bankAccount }}</span>
        <el-link
          type="primary"
          style="float: right"
          icon="el-icon-circle-plus"
          @click="addBankVisible = true"
          v-if="cardListLength < 5">添加收款账号</el-link>
      </div>
      <bank-card
        @setCardNumber="cardListLength = $event"
        :addBankVisible="addBankVisible"
        @setVisible="addBankVisible = $event"
        :selectInfo="user"
        :canReg="true"
        :ocompanyId="this.$route.query.ocompanyId"
      />
      <div class="submit_box">
        <el-checkbox v-model="checked">同意</el-checkbox>
        <el-link type="primary" @click="handleHelp" >《电签协议》电签帮助</el-link>
        <el-button
          type="primary"
          @click="sign"
          :loading="signLoading"
          :disabled="isDisabled">开始电签</el-button >
      </div>
    </el-card>
  </el-card>
</template>
<script>
import bankCard from "./components/bankCard.vue";
import api from "@/api/list";

export default {
  components: {
    bankCard,
    
  },
  data() {
    return {
      addBankVisible: false,
      cardListLength: 0,
      checked: false,
      isDisabled: true,
      signLoading: false,
      contractId: this.$route.query.contractId,
      ocompanyId: this.$route.query.ocompanyId,
      user: {
        _bankAccount: null,
        _bankName: null,
      },
    };
  },
  mounted() {
    this.getInfo(this.contractId,this.ocompanyId);
  },
  watch: {
    checked: function (v) {
      this.isDisabled = !v;
    }
  },
  methods: {
    getInfo(contractId,ocompanyId) {
      api.employeeGetAllInfo({ contractId,ocompanyId }).then((res) => {
        if (res.status == "200") {
          Object.assign(this.user, res.data);
          this.$set(this.user, "_bankAccount", this.user.bankAccount);
          this.$set(this.user, "_bankName", this.user.bankName);
        }
      });
    },
    handleHelp() {
      let routeData = this.$router.resolve({
        path: "/help",
      });
      window.open(routeData.href, "_blank");
    },
    sign() {
      if (this.user.certificationStatus == "AUTHENTICATIONOK") {
        // 已认证
        if (
          typeof this.user._bankName != "undefined" &&
          typeof this.user._bankAccount != "undefined"
        ) {
          // 银行卡名称和银行卡账号不为空(协议绑定了银行卡)
          if (
            typeof this.user.bankName != "undefined" &&
            typeof this.user.bankAccount != "undefined"
          ) {
            if (this.user.signUrl != null && this.user.signUrl != "") {
              window.open(this.user.signUrl, "_self");
            } else {
              // 向e签宝发起协议申请
              this.createEmployeeContract({
                id: this.contractId,
                url: "pcUrl",
              });
            }
          } else {
            api
              .bank(
                {
                  name: this.user._bankName,
                  bankNo: this.user._bankAccount,
                  contractId: this.contractId,
                },
                { method: "post" }
              )
              .then((res) => {
                if (res.status == "200") {
                  if (this.user.signUrl != null) {
                    window.open(this.user.signUrl, "_self");
                  } else {
                    // 向e签宝发起协议申请
                    this.createEmployeeContract({
                      id: this.contractId,
                      url: "pcUrl",
                    });
                  }
                }
              });
          }
        } else {
          this.$message({
            showClose: true,
            message: "请先选择收款账号！",
            type: "error",
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: "请先完成实名认证！",
          type: "error",
        });
      }
    },
    createEmployeeContract(obj) {
      // 向e签宝发起协议申请
      this.signLoading = true;
      api.createContract(obj, { method: "post" }).then((res) => {
        this.signLoading = false; //关闭加载效果
        console.log("res",res)
        if (res.status == "200") {
          window.open(res.data, "_self");
        } else {
          this.$message({
            showClose: true,
            message: "签署协议失败，请稍后再试！",
            type: "error",
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.box-card {
  .baseInfo {
    .item {
      line-height: 30px;
      font-size: 14px;
      margin-bottom: 12px;
      label {
        color: #999;
        margin-right: 6px;
      }

      span.value {
        color: #333;
        cursor: pointer;

        &.fail {
          color: #ff3838;
        }

        &.success {
          color: #61d9a5;
        }
      }
    }
  }
  .box-bank {
    position: relative;
    top: -44px;
    padding-left: 70px;
    :nth-child(1) {
      font-size: 14px;
      color: #333333;
    }
    :nth-child(2) {
      font-size: 14px;
      color: #8493fa;
    }
    :nth-child(3) {
      font-size: 14px;
      color: #8493fa;
    }
  }
  .submit_box {
    text-align: right;
    line-height: 30px;
    padding: 20px 0;

    /deep/ .el-button {
      margin-left: 20px;
    }

    /deep/ .el-link {
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
}
</style>
